/* General */
$fontSize:14px;
$bodyBgColor:#edf0f5;
$textColor:#333333;
$textSecondaryColor:#707070;
$borderRadius:3px;
$dividerColor:#e3e3e3;
$transitionDuration:.2s;
$maskBgColor:#424242;

/* Menu Common */
$menuitemBadgeBgColor:#007be5;
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;
$menuitemActiveRouteColor:#1fa1fc;

/* Menu Light */
$menuBgColorFirst:#f3f4f9;
$menuBgColorLast:#d7dbe8;
$menuitemColor:#232428;
$menuitemHoverColor:#0388e5;
$menuitemActiveColor:#0388e5;
$menuitemActiveBgColor:#fcfcfc;
$menuitemBorderColor:rgba(166, 170, 180, 0.6);


/* Menu Dark */
$menuDarkBgColorFirst:#4d505b;
$menuDarkBgColorLast:#3b3e47;
$menuitemDarkColor:#ffffff;
$menuitemDarkHoverColor:#0388e5;
$menuitemDarkActiveColor:#0388e5;
$menuitemDarkActiveBgColor:#2e3035;
$menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor:#CC7821;
$topbarRightBgColor:#df9f5a;

// $topbarLeftBgColor:#6da13d;
// $topbarRightBgColor:#8ab95e;

// $topbarLeftBgColor:#0388E5;
// $topbarRightBgColor:#07BDF4;

$topbarItemBadgeBgColor:#ffffff;
// $topbarItemBadgeBgColor:#ef6262;
$topbarItemBadgeColor:#ef6262;
$topbarItemColor:#ffffff;
$topbarItemHoverColor:#c3e8fb;
$topbarSearchInputBorderBottomColor:#ffffff;
$topbarSearchInputColor:#ffffff;

/* Footer */
$footerBgColor:#ffffff;
