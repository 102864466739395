/* General */
/* Menu Common */
/* Menu Light */
/* Menu Dark */
/* Topbar */
/* Footer */
.splash-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #CC7821;
  /* Old browsers */
  background: -moz-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #CC7821), color-stop(100%, #df9f5a));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* IE10+ */
  background: linear-gradient(to right, #CC7821 0%, #df9f5a 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$left, endColorstr=$right,GradientType=1 );
  /* IE6-9 */
}
.splash-screen .splash-container {
  width: 40px;
  height: 40px;
  margin: 0px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -20px;
}
.splash-screen .splash-double-bounce1, .splash-screen .splash-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: splash-bounce 2s infinite ease-in-out;
  animation: splash-bounce 2s infinite ease-in-out;
}
.splash-screen .splash-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes splash-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes splash-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-size: 14px;
  color: #333333;
  background-color: #edf0f5;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;
  min-height: 100vh;
}

.layout-topbar {
  position: fixed;
  height: 45px;
  padding: 0.7em 1.5em 0em 1.5em;
  color: #ffffff;
  z-index: 999;
  right: 0;
  background: #CC7821;
  /* Old browsers */
  background: -moz-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #CC7821), color-stop(100%, #df9f5a));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, #CC7821 0%, #df9f5a 100%);
  /* IE10+ */
  background: linear-gradient(to right, #CC7821 0%, #df9f5a 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$left, endColorstr=$right,GradientType=1 );
  /* IE6-9 */
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
}
.layout-topbar:after {
  content: "";
  display: table;
  clear: both;
}
.layout-topbar .layout-topbar-title {
  color: #fff;
  text-align: center;
  float: center;
  height: 35x;
  line-height: 30px;
  margin-right: 0px;
  font-style: bold;
  font-size: 25px;
  margin-top: -35px;
  -moz-animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.layout-topbar .layout-topbar-icons {
  float: right;
  display: block;
  -moz-animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.layout-topbar .layout-topbar-icons button {
  position: relative;
  color: #ffffff;
  margin-left: 20px;
  display: inline-block;
  text-decoration: none;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  overflow: visible;
}
.layout-topbar .layout-topbar-icons button:hover {
  color: #c3e8fb;
}
.layout-topbar .layout-topbar-icons button span.layout-topbar-icon {
  font-size: 2em;
}
.layout-topbar .layout-topbar-icons button span.layout-topbar-item-text {
  font-size: 20px;
  display: none;
}
.layout-topbar .layout-topbar-icons button span.layout-topbar-badge {
  position: absolute;
  font-size: 13px;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ef6262;
  background-color: #ffffff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search {
  padding: 0;
  position: relative;
  display: inline-block;
  top: -4px;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input {
  display: inline-block;
  border: 0 none;
  font-size: 14px;
  background: transparent;
  border-bottom: 2px solid #ffffff;
  outline: 0 none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff;
  width: 100px;
  padding: 1px 20px 1px 1px;
  margin: 0px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input:-moz-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input::-moz-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search input:-ms-input-placeholder {
  color: #ffffff;
  opacity: 0.7;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search .layout-topbar-search-icon {
  font-size: 18px;
  position: absolute;
  top: -1px;
  right: 0px;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input {
  border-bottom-color: #c3e8fb;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-webkit-input-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-moz-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input::-moz-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover input:-ms-input-placeholder {
  opacity: 1;
}
.layout-topbar .layout-topbar-icons .layout-topbar-search:hover .layout-topbar-search-icon {
  color: #c3e8fb;
}
.layout-topbar .layout-menu-button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.layout-topbar .layout-menu-button span {
  font-size: 2em;
}
.layout-topbar .layout-menu-button:hover {
  color: #c3e8fb;
}
.layout-topbar button {
  cursor: pointer;
}

.layout-sidebar {
  position: fixed;
  width: 250px;
  /*se for alterar o tamanho, altere o paddingLef do component DisplayBlock*/
  height: 100%;
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -moz-transition: left 0.2s;
  -o-transition: left 0.2s;
  -webkit-transition: left 0.2s;
  transition: left 0.2s;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
.layout-sidebar .layout-logo {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
}
.layout-sidebar .menuitem-badge {
  display: inline-block;
  margin-left: 4px;
  font-size: 10px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  background-color: #007be5;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.layout-profile {
  text-align: center;
  padding: 5px 0;
}
.layout-profile img {
  width: 48px;
  margin: 5px;
}
.layout-profile .layout-profile-link {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0.45em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.layout-profile .layout-profile-link i {
  display: inline-block;
  font-size: 16px;
  vertical-align: middle;
}
.layout-profile ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition-property: max-height;
  -moz-transition-property: max-height;
  -ms-transition-property: max-height;
  -o-transition-property: max-height;
  transition-property: max-height;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-profile ul.layout-profile-expanded {
  max-height: 500px;
}
.layout-profile ul li button {
  width: 100%;
  padding: 1em;
  border: 0 none;
  border-radius: 0;
  cursor: pointer;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.layout-profile ul li button:hover {
  color: #0388e5;
}
.layout-profile ul li button span {
  margin-left: 0.25em;
  vertical-align: middle;
}
.layout-profile ul li button i {
  vertical-align: middle;
}

.layout-sidebar-light .layout-profile .layout-profile-link {
  color: #232428;
}
.layout-sidebar-light .layout-profile .layout-profile-link:hover {
  color: #0388e5;
}
.layout-sidebar-light .layout-profile ul {
  background-color: #fcfcfc;
}
.layout-sidebar-light .layout-profile ul li button {
  color: #232428;
}
.layout-sidebar-light .layout-profile ul li button:hover {
  color: #0388e5;
}

.layout-sidebar-dark .layout-profile .layout-profile-link {
  color: #ffffff;
}
.layout-sidebar-dark .layout-profile .layout-profile-link:hover {
  color: #0388e5;
}
.layout-sidebar-dark .layout-profile ul {
  background-color: #2e3035;
}
.layout-sidebar-dark .layout-profile ul li button {
  color: #ffffff;
}
.layout-sidebar-dark .layout-profile ul li button:hover {
  color: #0388e5;
}

.layout-menu-container {
  padding-bottom: 120px;
}

.layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.layout-menu li a {
  cursor: pointer;
  position: relative;
  text-decoration: none;
  display: block;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
.layout-menu li a i {
  font-size: 18px;
  vertical-align: middle;
}
.layout-menu li a span {
  margin-left: 0.25em;
  vertical-align: middle;
  line-height: 18px;
  display: inline-block;
}
.layout-menu li a .menuitem-toggle-icon {
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -9px;
}
.layout-menu li a.router-link-active {
  font-weight: 700;
}
.layout-menu li.active-menuitem > a {
  width: 100%;
}
.layout-menu li.active-menuitem > a .menuitem-toggle-icon:before {
  content: "\e933";
}
.layout-menu li.active-menuitem > ul {
  max-height: 500px;
}
.layout-menu > li > a {
  padding: 1em;
}
.layout-menu > li > a span {
  font-size: 14px;
}
.layout-menu > li:last-child > a {
  border-bottom: 1px solid rgba(166, 170, 180, 0.6);
}
.layout-menu > li ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 1.5em;
  max-height: 0;
  overflow: hidden;
  -webkit-transition-property: max-height;
  -moz-transition-property: max-height;
  -ms-transition-property: max-height;
  -o-transition-property: max-height;
  transition-property: max-height;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.layout-menu > li ul li a {
  cursor: pointer;
  padding: 0.75em 1em;
  border-top: 0 none;
}
.layout-menu > li ul li a span {
  font-size: 13px;
}
.layout-menu > li ul li:last-child {
  padding-bottom: 1em;
}

.layout-sidebar-light {
  background: #f3f4f9;
  /* Old browsers */
  background: -moz-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f3f4f9), color-stop(100%, #d7dbe8));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f3f4f9 0%, #d7dbe8 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #f3f4f9 0%, #d7dbe8 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#000000",GradientType=0 );
  /* IE6-9 */
}
.layout-sidebar-light .layout-menu > li > a {
  border-top: 1px solid rgba(166, 170, 180, 0.6);
}
.layout-sidebar-light .layout-menu > li:last-child > a {
  border-bottom: 1px solid rgba(166, 170, 180, 0.6);
}
.layout-sidebar-light .layout-menu > li ul {
  background-color: #fcfcfc;
}
.layout-sidebar-light .layout-menu li a {
  color: #232428;
}
.layout-sidebar-light .layout-menu li a.router-link-active {
  color: #1fa1fc;
}
.layout-sidebar-light .layout-menu li a:hover {
  color: #0388e5;
}
.layout-sidebar-light .layout-menu li.active-menuitem > a {
  background-color: #fcfcfc;
  color: #0388e5;
}

.layout-sidebar-dark {
  background: #4d505b;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4d505b), color-stop(100%, #3b3e47));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #4d505b 0%, #3b3e47 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #4d505b 0%, #3b3e47 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#000000",GradientType=0 );
  /* IE6-9 */
}
.layout-sidebar-dark .layout-menu > li > a {
  border-top: 1px solid rgba(52, 56, 65, 0.6);
}
.layout-sidebar-dark .layout-menu > li:last-child > a {
  border-bottom: 1px solid rgba(52, 56, 65, 0.6);
}
.layout-sidebar-dark .layout-menu > li ul {
  background-color: #2e3035;
}
.layout-sidebar-dark .layout-menu li a {
  color: #ffffff;
}
.layout-sidebar-dark .layout-menu li a.router-link-active {
  color: #1fa1fc;
}
.layout-sidebar-dark .layout-menu li a:hover {
  color: #0388e5;
}
.layout-sidebar-dark .layout-menu li.active-menuitem > a {
  background-color: #2e3035;
  color: #0388e5;
}

.layout-main {
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
  padding: 50px 10px 10px 10px;
}

.layout-footer {
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
  background-color: #ffffff;
  height: 1.5em;
}
.layout-footer img {
  vertical-align: middle;
}
.layout-footer .footer-text {
  vertical-align: middle;
}

@media (min-width: 1025px) {
  .layout-wrapper.layout-overlay .layout-sidebar {
    left: -250px;
  }
  .layout-wrapper.layout-overlay .layout-topbar {
    left: 0;
  }
  .layout-wrapper.layout-overlay .layout-main,
.layout-wrapper.layout-overlay .layout-footer {
    margin-left: 0;
  }
  .layout-wrapper.layout-overlay.layout-overlay-sidebar-active .layout-sidebar {
    left: 0;
  }
  .layout-wrapper.layout-overlay.layout-overlay-sidebar-active .layout-topbar {
    left: 250px;
  }
  .layout-wrapper.layout-static .layout-sidebar {
    left: 0;
  }
  .layout-wrapper.layout-static .layout-topbar {
    left: 250px;
  }
  .layout-wrapper.layout-static .layout-main,
.layout-wrapper.layout-static .layout-footer {
    margin-left: 250px;
  }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-sidebar {
    left: -250px;
  }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-topbar {
    left: 0;
  }
  .layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-main,
.layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-footer {
    margin-left: 0;
  }
}
@media (max-width: 1024px) {
  .layout-wrapper .layout-topbar {
    left: 0;
  }
  .layout-wrapper .layout-main,
.layout-wrapper .layout-footer {
    margin-left: 0;
  }
  .layout-wrapper .layout-sidebar {
    left: -250px;
    margin-top: 45px;
  }
  .layout-wrapper .layout-mask {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 45px;
    left: 0;
    z-index: 998;
    background-color: #424242;
    opacity: 0.7;
    filter: alpha(opacity=70);
  }
  .layout-wrapper.layout-mobile-sidebar-active .layout-sidebar {
    left: 0;
  }
  .layout-wrapper.layout-mobile-sidebar-active .layout-mask {
    display: block;
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}
/* Typography */
h1 {
  font-weight: normal;
  margin: 0;
  font-size: 24px;
}

h2 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.p-g {
  -ms-flex-wrap: wrap;
}